import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import settings from "./backend/LocalSettings";
import VueResource from "vue-resource";
// import locale from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale/lang/ro"
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import BootstrapVue from "bootstrap-vue";

import VueSignaturePad from "vue-signature-pad"


import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import moment from "moment";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueResource);
Vue.use(ElementUI, { locale });
Vue.use(VueSignaturePad)


Vue.http.options.xhr = { withCredentials: true };
Vue.http.options.emulateJSON = true;
Vue.http.headers.common["Token"] = settings.get_token();

Vue.http.options.root = settings.BASE_URL;


Vue.filter( 'Valoare', function(value){ 
  if(!value) return "0";
  return parseFloat(value).toFixed(2);
} );

Vue.filter("momentformat", function (value) {
  return value;
});
Vue.filter( 'DataOra', function(value){
  if(!value) return "-";
  return moment(value).format("DD.MM.YYYY HH:mm:ss");
} );

Vue.filter( 'DataOraFaraSec', function(value){
  if(!value) return "-";
  return moment(value).format("DD.MM.YYYY HH:mm");
} );

Vue.filter( 'Data', function(value){
  if(!value) return "-";
  return moment(value).format("DD.MM.YYYY");
} );

Vue.prototype.$has_right = function (rightKey) {
  var ret = false;
  var drepturiDecoded = settings.get_drepturi();
  var numarUnic = (drepturiDecoded.length - 1) * (458 + 73 - 23);
  var caractereUnice = "nimic" + numarUnic;
  var verificareCrc = crypto
    .createHash("md5")
    .update(Buffer.from(caractereUnice))
    .digest("hex");
  if (drepturiDecoded.indexOf(verificareCrc) !== -1) {
    var indexDrept = drepturiDecoded.indexOf(rightKey);
    if (indexDrept !== -1) ret = true;
  }
  return ret;
};

Vue.prototype.$post = async function (url, args = {}) {
  // this.loadingVisile = true;
  var response = await this.$http.post(url, args);
  // this.loadingVisile = false;
  if (settings.verify_response(response)) {
    return response.data;
  }
};

new Vue({
  router,

  render: (h) => h(App),
}).$mount("#app");
