import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    // { path: "/", name: "home", component: () => import("./pages/Login.vue"), props: (route) => ({
    //   cod: route.params.cod || null
    // }) },

    { path: "/", name: "home", component: () => import("./pages/Login.vue") },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("./pages/Dashboard/Dashboard.vue"),
    },
    {
      path: "/locuinte",
      name: "locuinte",
      component: () => import("./pages/Locuinte/Locuinte.vue"),
    },
    {
      path: "/masini",
      name: "masini",
      component: () => import("./pages/Masini/Masini.vue"),
    }, 
    {
      path: "/oferte",
      name: "oferte",
      component: () => import("./pages/Oferte.vue"),
    },
    {
      path: "/change_password/:token",
      name: "parola_noua",
      component: () => import("./pages/Parola_uitata.vue"),
    },
    {
      path: "/editare_cont",
      name: "editare_cont",
      component: () => import("./pages/Cont/EditareCont.vue"),
    },
    {
      path: "/sanatate",
      name: "sanatate",
      component: () => import("./pages/Sanatate/Sanatate.vue"),
    },
    {
      path: "/alte-asigurari",
      name: "alte-asigurari",
      component: () => import("./pages/AlteAsigurari/AlteAsigurari.vue"),
    },
    {
      path: "/plati",
      name: "plati",
      component: () => import("./pages/Plati/Plati_list.vue"),
    },
    {
      path: "/raspundere-profesionala",
      name: "raspundere-profesionala",
      component: () =>
        import("./pages/RaspundereProfesionala/RaspundereProfesionala.vue"),
    },
    {
      path: "/polite-active",
      name: "polite-active",
      component: () => import("./pages/PoliteActive/PoliteActive.vue"),
    },
    {
      path: "/polite-client",
      name: "polite-client",
      component: () => import("./pages/PoliteClient/PoliteClient.vue"),
    },
    { 
      path: '/multumim/:orderid/:status', 
      name: 'multumim', 
      component: () => import('./pages/Multumim.vue') 
    },
    { 
      path: '/termeni-si-conditii', 
      name: 'termeni-si-conditii', 
      component: () => import('./pages/TermeniConditii/TermeniSiConditii.vue') 
    },
    { 
      path: '/politica-cookie', 
      name: 'politica-cookie', 
      component: () => import('./pages/PoliticaCookie/PoliticaCookie.vue') 
    },
  ],
});
